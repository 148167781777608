<template>
  <div class="menu-item">
    <ul>
      <li
        v-for="(item, index) in menuList"
        :key="index"
        class='dropdown'
        :class='item.class'
        @mouseover="onHoverTop($event)"
        @click="onTopClick($event,item)"
      >
        <a
          href="javascript:void(0)"
          class="dropbtn"
        >{{ item.caption }}</a>
        <div class="dropdown-content">
          <a v-for="(sub, index1) in item.subMenu" :key="index1"
            :class= 'sub.class'
            href="javascript:void(0)"
            @click="onMenuClick($event,sub)"
            @mouseover="onHover($event)"
          >{{ sub.caption }}</a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import AppLib from '../AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import HttpService from '../service/HttpService'

export default {
  name: 'TopMenuSimpleComponent',
  props: {
    menuList: {
      type: [Array],
      default: function () {
        return []
      }
    }
  },
  data: function () {
    return {
      beforeSelectedCaption: null,
      beforeSelectedMenu: null
    }
  },
  created () {
  },
  mounted () {
    this.$Q('.menu-item li').first().addClass('selected-top-menu')
    this.setPermission()
    this.$store.commit('saveTopmenu', this.menuList)
    console.log(this.menuList)
    setTimeout(() => {
      this.selectMenu(null)
    }, 200)
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    setPermission () {
      const userGroup = this.$store.getters.getVendorInfo.permission
      // if (this.$store.getters.getVendorInfo.crmPermission !== ConstDef.CRM_MASTER_HEAD)
      // // // console.log(this.menuList)
      // topMenu search
      // console.log(this.menuList)
      for (let i = 0; i < this.menuList.length; i++) {
        const className = '.' + this.menuList[i].class
        // // // console.log(this.menuList[i].menuId)
        if (this.menuList[i].menuId === '50000') { // system menu
          // // // console.log(this.$store.getters.getVendorInfo.crmPermission)
          // // // console.log(className)
          if (this.$store.getters.getVendorInfo.crmPermission !== ConstDef.CRM_MASTER_HEAD) {
            this.$Q(className).hide()
          }
        }
        if ((this.menuList[i].permission & userGroup) === 0) {
          console.log(this.menuList[i].permission & userGroup)
          this.$Q(className).hide()
        }
        if (this.menuList[i].subMenu !== undefined) {
          for (let j = 0; j < this.menuList[i].subMenu.length; j++) {
            // // // console.log(this.menuList[i].subMenu[j].permission & userGroup)
            if ((this.menuList[i].subMenu[j].permission & userGroup) === 0) {
              const subClass = '.' + this.menuList[i].subMenu[j].class
              // // // console.log(subClass)
              this.$Q(subClass).hide()
            }
          }
        }
      }
    },
    getToken (goUrl) {
      let token = this.$store.getters.getCrmToken
      const 이메일 = this.$store.getters.getVendorInfo.이메일
      // // // console.log(token)
      const now = AppLib.nowTimestamp() + 10 * 60000
      const url = AppLib.replaceAll(goUrl, '/crm', '')
      // // // console.log(url)
      if (token === null || token.expireDate < now || token.이메일 !== 이메일) {
        HttpService.reqPostParamModel('/getCrmToken', JSON.stringify({}))
          .then((data) => {
            token = JSON.parse(data.body)
            // // // console.log(token)
            this.$store.commit('saveCrmToken', token)
            const encodeUrl = (`token=${token.token}&url=${url}`)
            window.open(`http://crm.angelplus.kr?${encodeUrl}`, '_blank')
          })
          .catch(error => {
            if (error.response.status === ConstDef.GENERRAL_ERROR) {
              this.$snotify.error(error.response.data.message)
            } else {
              this.$snotify.error(`에러코드 : ${error.response.status}`)
            }
          })
      } else {
        const encodeUrl = encodeURI(`token=${token.token}&url=${url}`)
        window.open(`http://crm.angelplus.kr?${encodeUrl}`, '_blank')
      }
    },
    onTopClick (e, item) {
      console.log(item)
      const current = this.$route.path
      if (current.indexOf(item.url) >= 0) {
        return
      }
      if (item.subMenu.length === 0) {
        if (this.beforeSelectedMenu !== null) {
          this.beforeSelectedMenu.caption = this.beforeSelectedCaption
          this.beforeSelectedMenu = null
        }
        this.selectMenu(item.url)
        this.$router.push(item.url)
      }
    },
    onMenuClick (e, item) {
      // // console.log(item.url)
      this.$Q('.dropdown-content').hide()
      const current = this.$route.path
      if (item.url.indexOf('/crm/') >= 0) {
        this.getToken(item.url)
        return
      }
      if (current === item.url) {
        return
      }
      this.selectMenu(item.url)
      this.$router.push(item.url)
    },
    onHover (e) {
      this.$Q('.dropdown:hover .dropdown-content').show()
    },
    onHoverTop (e) {
      this.$Q('.dropdown:hover .dropdown-content').show()
    },
    selectHomeMenu () {
      this.$Q('.menu-item li').removeClass('selected-top-menu')
      this.$Q('.menu-item li').first().addClass('selected-top-menu')
    },
    selectMenu (url) {
      this.$Q('.menu-item li').removeClass('selected-top-menu')
      let current = url
      if (url === null) {
        current = this.$route.path
      }
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].url === current) {
          this.$Q(`.${this.menuList[i].class}`).addClass('selected-top-menu')
          return
        }
        for (let j = 0; j < this.menuList[i].subMenu.length; j++) {
          if (this.menuList[i].subMenu[j].url === current) {
            // // console.log(this.menuList[i].subMenu[j].url)
            this.$Q(`.${this.menuList[i].class}`).addClass('selected-top-menu')
            this.$Q(`.${this.menuList[i].class}`).attr('class')
            if (this.beforeSelectedMenu === null) {
              this.beforeSelectedMenu = this.menuList[i]
              this.beforeSelectedCaption = this.beforeSelectedMenu.caption
              this.beforeSelectedMenu.caption += ` : ${this.menuList[i].subMenu[j].caption}`
            } else if (this.beforeSelectedMenu.menuId !== this.menuList[i].subMenu[j].parentMenuId) {
              this.beforeSelectedMenu.caption = this.beforeSelectedCaption
              this.beforeSelectedMenu = this.$_.findWhere(this.menuList, { menuId: this.menuList[i].subMenu[j].parentMenuId })
              this.beforeSelectedCaption = this.beforeSelectedMenu.caption
              this.beforeSelectedMenu.caption += ` : ${this.menuList[i].subMenu[j].caption}`
            } else {
              this.beforeSelectedMenu.caption = `${this.beforeSelectedCaption} : ${this.menuList[i].subMenu[j].caption}`
            }
            return
          }
        }
      }
    }
  }
}
/*
options ={
  height: 42,
  topMenuHoverColor,
  topMenuFontColor:white,
  subMenuHoverColor,
  subMenuFontColor:white,
}
*/

</script>

<style lang="scss">
.menu-item{
  margin-left:240px;
  ul {
    height:60px;
    list-style-type: none;
    margin: 0;
    padding-top: 0px;
    overflow: hidden;
    li{
      height:60px;
    }
  }

  .dropdown-content a{
    display: inline-block;
    color: black;
    text-align: center;
    padding: 0px 16px;
    text-decoration: none;
    height:40px;
  }
  .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 20px 18px;
    text-decoration: none;
    height:60px;
    font-size: 1.2rem;
  }

  .dropdown:hover {
    background-color: #d4710e;
  }

  li.dropdown {
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    z-index: 1;
    border:1px solid rgb(207, 207, 207);
  }

  .dropdown-content a {
    color: white;
    padding: 10px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-bottom:1px solid rgb(224, 224, 224);
    background-color: rgb(82,83,97);
    font-size: 1.1rem;
  }

  .dropdown-content a:hover {
    background-color: #ff8711;
    font-weight: 700;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
  .back-black{
   background-color: red;
  }
  .selected-top-menu {
    background-color: #ff8711;
  }

}

</style>
