<template>
  <div class="main-page">
     <div class="top-menu">
      <!--<div class="logo float-left" style="color: black; font-size: 1.8rem; font-weight: bold;">Hongikit<span style="color: red; font-size: 1.8rem; font-weight: bold;"> Hi-SFS</span></div>
      -->
      <div class='logo'><img class="float-left" src="/image/logo.png" /><div class="float-left title" >MEGA - MES</div></div>
      <div class="user-info" v-if='userImage !== null'>
        <!-- <img :src="userImage"> -->
        <img src="/image/user-circle.png">
      </div>
      <div class='user-info'  @click='onMyPage'><img :src='userImage'></div>
      <div class='user-name'>{{vendorInfo.거래처명}}</div>
      <div class="log-out">
        <dx-button
          icon='pinleft'
          style="font-size=0.9rem;height:30px;margin-top:2px;background-color:#ff8711"
          text="로그아웃"
          type="default"
          styling-mode="contained"
          :focus-state-enabled="false"
          @click="onLogout($event)"
        />
      </div>
      <TopMenuSimpleComponent
        v-if="topMenu"
        ref="topMenuComponent"
        :menu-list="topMenu"
      />
    </div>
    <router-view/>
  </div>
</template>

<script>

import ConstDef from '../../../MesSmartVue/src/share/ConstDef'
// import { DxSelectBox, DxCheckBox, DxTextBox, DxDateBox, DxButton } from 'devextreme-vue'
import { DxButton } from 'devextreme-vue'
import TopMenuSimpleComponent from '../share/component/TopMenuSimpleComponent'
import FindAllTopMenuProc from '../share/service/FindAllTopMenuProc'
// import AppLib from '../share/AppLib'
import axios from 'axios'

export default {
  name: 'login',
  data: function () {
    return {
      topMenuHeight: null,
      topMenu: null,
      vendorInfo: null,
      userImage: '/image/user-circle.png'
    }
  },
  components: {
    DxButton,
    TopMenuSimpleComponent
  },
  async created () {
    // this.topMenu = this.$store.getters.getTopMenu
    this.vendorInfo = this.$store.getters.getVendorInfo
    // console.log('=====' + this.vendorInfo)
    if (this.vendorInfo === undefined || this.vendorInfo === null) {
      this.$router.push('/')
      return
    }
    const baseData = this.$store.getters.getBaseData
    // console.log(baseData)
    if (baseData === null || baseData === undefined) {
      const findTopMenu = FindAllTopMenuProc.SendParamModel()
      const sysParam = this.$_sp.runNoEncodeFindProc('spFindAllSysParamByNoSql', {})
      const baseCode = this.$_sp.runNoEncodeFindSqlProc('공통코드', null)
      const customer = this.$_sp.runNoEncodeFindSqlProc('거래처정보', { 거래처구분코드: '발주사' })
      const company = this.$_sp.runNoEncodeFindSqlProc('거래처정보', null)
      const carType = this.$_sp.runNoEncodeFindSqlProc('고객사생산품목', null)
      const raw = this.$_sp.runNoEncodeFindProc('spFindAllRawMeterialLookup', {})
      const topMenuTab = this.$_sp.runNoEncodeFindProc('spFindAllB2bTopMenuForTabName', {})
      await axios.all([findTopMenu, baseCode, sysParam, customer, carType, raw, company, topMenuTab])
        .then(axios.spread((...reponse) => {
          if (reponse[0].result === ConstDef.SUCCESS) {
            this.topMenu = FindAllTopMenuProc.MakeModel(reponse[0], this.$store)
            // console.log(reponse[0])
            this.$store.commit('saveBaseData', this.$_sp.MakeModel(reponse[1]))
            const sysParam = this.$_sp.MakeModel(reponse[2])
            this.$store.commit('saveSysParam', sysParam)
            ConstDef.USER_PERMISSION = JSON.parse(this.$_.findWhere(sysParam, { key: ConstDef.SYS_USER_PERMISSION_KEY }).value)
            const ENCODE_BASE64 = this.$_.findWhere(sysParam, { key: 'QR_BASE64_ENCODE' }).value
            // console.log(ENCODE_BASE64)
            if (ENCODE_BASE64 === 'true') {
              ConstDef.ENCODE_BASE64 = true
            } else {
              ConstDef.ENCODE_BASE64 = false
            }
            // console.log('ConstDef.ENCODE_BASE64=', ConstDef.ENCODE_BASE64)
            this.$store.commit('saveCustomerInfo', this.$_sp.MakeModel(reponse[3]))
            this.$store.commit('saveCarType', this.$_sp.MakeModel(reponse[4]))
            this.$store.commit('saveRawMeterial', this.$_sp.MakeModel(reponse[5]))
            this.$store.commit('saveCompany', this.$_sp.MakeModel(reponse[6]))
            this.$store.commit('saveTopMenuTab', this.$_sp.MakeModel(reponse[7]))
          }
        }))
    } else {
      FindAllTopMenuProc.SendParamModel()
        .then((data) => {
          this.isLoading = false
          if (data.result === ConstDef.SUCCESS) {
            this.topMenu = FindAllTopMenuProc.MakeModel(data, this.$store)
          }
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  },
  mounted () {
    // console.log('**************addEventListener')
    window.addEventListener('resize', this.onResize)
  },
  updated () {
    this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - 80) + 'px')
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize (e) {
      this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - 80) + 'px')
      this.$Q('.sub-content').css('height', (window.innerHeight - 50) + 'px')
    },
    addMenuClass (menu) {
      for (let i = 0; i < menu.length; i++) {
        menu[i].class = `top-menu${i}`
        for (let j = 0; j < menu[i].subMenu.length; j++) {
          menu[i].subMenu[j].class = `sub-menu${i}${j}`
        }
      }
      // console.log(menu)
    },
    gotoDefaultPage () {
      const permission = this.$store.getters.getVendorInfo.permission
      // console.log(permission)
      for (let i = 0; i < this.topMenu.length; i++) {
        for (let j = 0; j < this.topMenu[i].subMenu.length; j++) {
          if (this.topMenu[i].subMenu[j].permission & permission) {
            // console.log(this.topMenu[i].subMenu[j].url)
            return this.topMenu[i].subMenu[j].url
          }
        }
      }
    },
    handleResize () {
      // this.$Q('.sub-title').css('width', (this.topMenuW) + 'px')
      this.$Q('.sub-title-wrap ul').css('width', (this.subPageW) + 'px')
    },
    onLogout (e) {
      this.vendorInfo = this.$store.getters.getvendorInfo
      this.$router.push('/logout')
    },
    onHome () {
      const current = this.$route.path
      const home = this.gotoDefaultPage()
      // console.log(home)
      if (current !== home) {
        this.$router.push(this.gotoDefaultPage())
        this.$refs.topMenuComponent.selectSubMenu()
      }
    },
    onMyPage () {
      // // console.log(this.$route)
      this.$router.push(`/myPage?beforeUrl=${this.$route.path}`)
    }
  }
}

</script>

<style lang="scss">
.main-page{
  z-index: 1000;
  .top-menu{
    background-color: white;
    z-index: 1000;
    position: fixed;
    top:0;
    left:0;
    height:60px;
    width:100%;
    background-color:rgba(73, 73, 73, 0.918);
    .logo {
        margin-top: 16px;
        margin-left: 30px;
        display: block;
        float: left;
        height:24px;
        img{
          height: 100%;
        }
    }
    .logo:hover {
      cursor: pointer;
    }
    .user-info {
      position:absolute;
      right:190px;
      color:white;
      top:12px;
      border-radius: 50%;
      height:36px;
      width:36px;
      overflow: hidden;
      img{
        width:100%;
      }
    }
    .user-name {
      position:absolute;
      right:100px;
      width:85px;
      color:white;
      top:20px;
      font-weight: bold;
      overflow: hidden;
      text-align: center;
    }
    .user-info:hover {
      cursor: pointer;
    }
    .log-out{
      position:absolute;
      right:10px;
      top: 13px;

    }
    .title {
      font-size: 1.2rem;
      color: white;
      font-weight: bold;
    }
  }

}

</style>
