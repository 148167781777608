
import HttpService from './HttpService'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import ParamModel from '../domain/ParamModel'
import AesCrypto from './AesCrypto'
import UserGroupModel from '../domain/UserGroupModel'

export default class FindAllTopMenuProc {
  static SendParamModel () {
    const jsonObject = {
      proc: 'spFindAllB2bTopMenu',
      ps: {
      }
    }
    const plainText = JSON.stringify(jsonObject) // '{"version":"20180509.1","userId":"U00488","password":"eluonlbs","session":"nx49ea2hmv","forceCont":true,"receiver":true}'
    const encode = AesCrypto.GetEncryptString(plainText)
    const param = new ParamModel()
    param.command = ConstDef.CONNECT
    param.body = encode
    return HttpService.select(param)
  }

  static MakeModel (data, store) {
    const json = JSON.parse(data.body)
    const topMenu = []

    let menuItem = null
    /*
    // let userGroupList = ConstDef.USER_PERMISSION
    // // console.log(userGroupList)

    //   { groupName: '시스템관리자', permission: 1 },
    for (let i = 0; i < userGroupList.length; i++) {
      let item = { 'groupName': userGroupList[i].groupName, 'permission': userGroupList[i].permission }
      userGroupArray.push(item)
    } */
    const userGroupArray = ConstDef.USER_PERMISSION
    // // console.log(json.length)
    for (let i = 0; i < json.length; i++) {
      if (json[i].parentMenuId === '0') {
        if (menuItem !== null) {
          topMenu.push(menuItem)
        }
        menuItem = {}
        menuItem.class = `topmenu${i}`
        menuItem.parentMenuId = json[i].parentMenuId
        menuItem.menuId = json[i].menuId
        menuItem.caption = json[i].menuName
        // // console.log(json[i].menuName)
        menuItem.url = json[i].url
        menuItem.permission = json[i].permission
        menuItem.subMenu = []
        menuItem.userGroup = []
        for (let j = 0; j < userGroupArray.length; j++) {
          const group = new UserGroupModel()
          group.groupName = userGroupArray[j].groupName
          group.checked = (((json[i].permission >> j) & 0x01) === 0x01)
          group.permission = userGroupArray[j].permission
          menuItem.userGroup.push(group)
        }
      } else {
        const subMenu = {}
        subMenu.parentMenuId = json[i].parentMenuId
        subMenu.menuId = json[i].menuId
        subMenu.caption = json[i].menuName
        subMenu.url = json[i].url
        subMenu.permission = json[i].permission
        subMenu.userGroup = []
        for (let j = 0; j < userGroupArray.length; j++) {
          const group = new UserGroupModel()
          group.groupName = userGroupArray[j].groupName
          group.checked = (((json[i].permission >> j) & 0x01) === 0x01)
          group.permission = userGroupArray[j].permission
          subMenu.userGroup.push(group)
        }
        menuItem.subMenu.push(subMenu)
      }
    }
    topMenu.push(menuItem)

    console.log(topMenu)
    return topMenu
  }
}
